











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import QuickAccessDataTable from "@/shared/components/data-table/quick-access/QuickAccessDataTable.vue";
import __ from "@/shared/helpers/__";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import FormField from "@/shared/components/form/FormField.vue";
import { FieldSizes } from "@/shared/classes/form/field";
import _ from "lodash";
import { getQueryName } from "@/shared/helpers/query.helper";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import CourseAssignment from "@/shared/modules/course-assignments/course-assignment.model";
import ICourseAssignmentResponse from "@/shared/modules/course-assignments/course-assignments-response.interface";

@Component({
  components: { FormField, QuickAccessDataTable, },
  methods: { __ }
})
export default class UserCourseStatistic extends Vue {
  @Prop() userUuid!: string
  http = baseHttp
  courses: Record<string, any> = {}
  summary = {
    total: 0,
    pending: 0,
    passed: 0
  }

  panel = 0
  categorySelect: SearchableField | null = null
  categorySelectValue = null
  searchQuery: string = ''
  debouncedGetCourses!: () => void

  created() {
    this.getCourses();
    this.debouncedGetCourses = _.debounce(this.getCourses, 300)

    this.categorySelect = new SearchableField()
      .setKey('responsibleUserUuid')
      .setTitle(__('components.user-course-statistics.search-category'))
      .loadItems({
        endpoint: `${getNodeApiUrlByVersion()}/course-categories`,
        value: 'uuid',
        title: 'name',
        perPage: 20,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.categorySelectValue)
      .setOnChange((value) => this.categorySelectValue = value)
      .setSize(FieldSizes.threeTwelfth)

    this.$watch('categorySelectValue', () => this.getCourses())
    this.$watch('searchQuery', () => this.debouncedGetCourses())
    this.$watch('userUuid', () => this.getCourses())
  }

  getCourses() {
    const userUuid = this.userUuid ||  this.$route.params.uuid

    const query = [];
    if (this.categorySelectValue) query.push(`${getQueryName(FilterOperators.equals, 'courseCategoryUuid')}=${this.categorySelectValue}`);
    if (this.searchQuery) query.push(`q=${this.searchQuery}`);

    this.http.get(`${getNodeApiUrlByVersion()}/course-assignments/for-user-with-statistics/${userUuid}${query.length ?
      `?${query.join('&')}` : ''}`)
      .then(response => {
        const groupedCourses: Record<string, any> = {};
        const summary = { total: 0, pending: 0, passed: 0 };
        response.data.data.map((assignment: ICourseAssignmentResponse) => {
          if (!(assignment.course.courseCategory.uuid in groupedCourses)) groupedCourses[assignment.course.courseCategory.uuid] = {
            category: assignment.course.courseCategory.name,
            assignments: []
          }

          groupedCourses[assignment.course.courseCategory.uuid].assignments.push(new CourseAssignment(assignment));

          if (assignment.status !== CourseAssignment.STATUS_UNASSIGNED) summary.total++;
          if (!!assignment.testScore) summary.passed++;
          else if (assignment.status !== CourseAssignment.STATUS_UNASSIGNED) summary.pending++;
        });

        this.courses = groupedCourses;
        this.summary = summary;
      })
  }

  countTheoryProgress(assignment: CourseAssignment) {
    if (!assignment || assignment.lastMaterialViewedIndex === null) return 0;
    const percents = Math.round((assignment.lastMaterialViewedIndex + 1) * 100 / assignment.course.materialsAmount);
    return Math.min(percents, 100);
  }
}
