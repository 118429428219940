














































import { Component, Vue } from "vue-property-decorator";
import QuickAccessDataTable from "@/shared/components/data-table/quick-access/QuickAccessDataTable.vue";
import __ from "@/shared/helpers/__";
import { getNodeApiUrlByVersion } from "@/config";
import Course from "@/shared/modules/courses/course.model";
import baseHttp from "@/shared/http";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import FormField from "@/shared/components/form/FormField.vue";
import { FieldSizes } from "@/shared/classes/form/field";
import _ from "lodash";
import { getQueryName } from "@/shared/helpers/query.helper";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import CourseAssignment from "@/shared/modules/course-assignments/course-assignment.model";

@Component({
  components: { FormField, QuickAccessDataTable, },
  methods: { __ }
})
export default class UserCourseAssignment extends Vue {
  http = baseHttp
  courses: Record<string, any> = {}
  selectedCourses: Record<string, boolean> = {}

  categorySelect: SearchableField | null = null
  categorySelectValue = null
  searchQuery: string = ''
  debouncedGetCourses!: () => void

  created() {
    this.getCourses();
    this.debouncedGetCourses = _.debounce(this.getCourses, 300)

    this.categorySelect = new SearchableField()
      .setKey('responsibleUserUuid')
      .setTitle(__("admin.components.views.users.course-assignment.search-category"))
      .loadItems({
        endpoint: `${getNodeApiUrlByVersion()}/course-categories`,
        value: 'uuid',
        title: 'name',
        perPage: 20,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.categorySelectValue)
      .setOnChange((value) => this.categorySelectValue = value)
      .setSize(FieldSizes.threeTwelfth)

    this.$watch('categorySelectValue', () => this.getCourses())
    this.$watch('searchQuery', () => this.debouncedGetCourses())
  }

  getCourses() {
    const query = ['page_size=1000'];
    if (this.categorySelectValue) query.push(`${getQueryName(FilterOperators.equals, 'courseCategoryUuid')}=${this.categorySelectValue}`);
    if (this.searchQuery) query.push(`q=${this.searchQuery}`);

    this.http.get(`${getNodeApiUrlByVersion()}/courses${query.length ? `?${query.join('&')}` : ''}`)
      .then(response => {
        const groupedCourses: Record<string, any> = {};

        response.data.data.map((course: Course) => {
          if (!(course.courseCategory.uuid in groupedCourses)) groupedCourses[course.courseCategory.uuid] = {
            category: course.courseCategory.name,
            courses: []
          }

          groupedCourses[course.courseCategory.uuid].courses.push(course);
        });

        this.courses = groupedCourses;
      })

    this.http.get(`${getNodeApiUrlByVersion()}/course-assignments/for-user/${this.$route.params.uuid}?page_size=1000`)
      .then(response => {
        const selected: Record<string, any> = {};
        response.data.data.map((courseAssignment: CourseAssignment) => {
          if (courseAssignment.status != CourseAssignment.STATUS_UNASSIGNED) selected[courseAssignment.courseUuid] = true;
        });
        this.selectedCourses = { ...selected };
      })
      .catch(console.warn)
  }

  save() {
    const assign: string[] = []
    const unAssign: string[] = [];
    Object.keys(this.selectedCourses).forEach(uuid => {
      if (this.selectedCourses[uuid]) assign.push(uuid);
      else unAssign.push(uuid);
    })

    this.http.post(`${getNodeApiUrlByVersion()}/course-assignments`, {
      userUuid: this.$route.params.uuid,
      assignCourseUuids: assign,
      unAssignCourseUuids: unAssign,
    })
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __("admin.components.views.users.course-assignment.assign-success"),
        });
      })
      .catch(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.error,
          message: __("components.snack-bar.something-wrong"),
        });
      })
  }
}
