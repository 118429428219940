














import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import HeaderTypes from '@/shared/components/data-table/HeaderTypes.vue'

@Component({
  components: {
    HeaderTypes,
  }
})
export default class QuickAccessTable extends Vue {
  @Prop() table!: DataTableBase
  @Prop() item!: Model<IModelResponse>
}
