






import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import QuickAccessTable from '@/shared/components/data-table/quick-access/QuickAccessTable.vue'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import _ from 'lodash'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'

@Component({
  components: {
    QuickAccessTable,
  }
})
export default class QuickAccessDataTable extends Vue {
  @Prop() table!: DataTableBase
  @Prop() item!: Model<IModelResponse>
  modifiedTable: DataTableBase | null = null

  created() {
    this.modifiedTable = _.cloneDeep(this.table)

    this.modifiedTable
      .setHeaders(
        this.modifiedTable.headers
          .filter((item: DataTableHeader) => item.type !== DataTableHeaderTypes.multipleCheckbox && item.type !== DataTableHeaderTypes.action)
          .map((item: DataTableHeader) => item.setSortable(false))
      )
  }
}
