import Model from '@/shared/classes/model'
import ICoursesResponse from "@/shared/modules/courses/courses-response.interface";
import Course from "@/shared/modules/courses/course.model";
import ICourseAssignmentResponse from "@/shared/modules/course-assignments/course-assignments-response.interface";
import { formatDate } from "@/shared/helpers/formatDate.helper";
import moment from "moment";

export default class CourseAssignment extends Model<ICourseAssignmentResponse> {
  static STATUS_ASSIGNED = 'assigned'
  static STATUS_COMPLETED = 'completed'
  static STATUS_UNASSIGNED = 'unassigned'

  courseUuid!: string
  course!: ICoursesResponse
  status!: string;
  testScore!: number;
  createdAt!: string;
  testAttempts!: number
  materialsAttempts!: number
  lastTestAttempt!: null | object
  lastAttempt!: null | object
  testPassedAt!: null | string
  lastVisitedAt!: null | string
  timeSpentInSeconds!: number
  timeSpent!: string
  mostAnswersTestAttempt!: null | Record<string, any>
  lastMaterialViewedIndex!: null | number

  transform(data: ICourseAssignmentResponse): void {
    super.transform(data)

    this.courseUuid = data.courseUuid
    this.status = data.status
    this.testScore = data.testScore
    this.createdAt = formatDate(data.createdAt)
    this.testAttempts = data.testAttempts
    this.materialsAttempts = data.materialsAttempts
    this.lastTestAttempt = data.lastTestAttempt && {
      ...data.lastTestAttempt,
      createdAt: formatDate(data.lastTestAttempt.createdAt)
    }
    this.lastAttempt = data.lastAttempt && { ...data.lastAttempt, createdAt: formatDate(data.lastAttempt.createdAt) }
    this.testPassedAt = data.testPassedAt && formatDate(data.testPassedAt)
    this.lastVisitedAt = data.lastVisitedAt && formatDate(data.lastVisitedAt)
    this.timeSpentInSeconds = data.timeSpentInSeconds
    this.timeSpent = data.timeSpentInSeconds ?
      `${moment.utc((data.timeSpentInSeconds || 0) * 1000).format('HH:mm:ss')}` : "—"
    this.mostAnswersTestAttempt = data.mostAnswersTestAttempt
    this.lastMaterialViewedIndex = data.lastMaterialViewedIndex

    this.course = this.setRelation(Course, data.course)
  }
}
